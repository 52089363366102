@import "variables";

/* Center ratings in container */
.rating-container{
    // padding:20px;
}
.box-example-1to10 .br-wrapper {
    width: 210px;
    position: absolute;
    margin: 0px 0 0 -105px;
    left: 50%;
}

.box-example-movie .br-wrapper {
    width: 250px;
    position: absolute;
    margin: 0px 0 0 -125px;
    left: 50%;
}

.box-example-square .br-wrapper {
    width: 190px;
    position: absolute;
    margin: 0px 0 0 -95px;
    left: 50%;
}

.box-example-pill .br-wrapper {
    width: 232px;
    position: absolute;
    margin: 0px 0 0 -116px;
    left: 50%;
}

.box-example-reversed .br-wrapper {
    padding-top: 1.3em;
    width: 356px;
    position: absolute;
    margin: 0px 0 0 -178px;
    left: 50%;
}

.box-example-horizontal .br-wrapper {
    width: 120px;
    position: absolute;
    margin: 0px 0 0 -60px;
    left: 50%;
}

/* Display star ratings */

.star-ratings {
    h1 {
        font-size: 1.5em;
        line-height: 2;
        margin-top: 3em;
        color: $textColor;
    }

    p {
        margin-bottom: 3em;
        line-height: 1.2;
    }

    h1, p {
        text-align: center;
    }

    .stars {
        width: 205px;
        text-align:center;
        .title {
            font-size:14px;
            color:#cccccc;
            line-height:3;
            font-weight:600;
        }

        select {
            width: 120px;
            font-size: 16px;
        }
    }

    .stars-example-fontawesome,
    .stars-example-css,
    .stars-example-bootstrap {
        float: left;
    }

    .stars-example-fontawesome-o {
        // width: 200px;

        select {
            width: 200px;
        }
    }
}

.start-ratings-main {
    margin-bottom: 3em;
}

/* Boxes */

.box {
    width: 100%;
    float: left;
    margin: 1em 0;

    .box-header {
        text-align: center;
        font-weight: 400;
        padding: .5em 0;
    }

    .box-body {
        padding-top: 2em;
        height: 85px;

        /* rating widgets will be absolutely centered relative to box body */
        position: relative;
    }

    select {
        width: 120px;
        margin: 10px auto 0 auto;
        display: block;
        font-size: 16px;
    }
}

.box-large {
    .box-body {
        padding-top: 2em;
        height: 120px;
    }
}

@mixin box-color($color){
    .box-header {
        background-color: $color;
        color: white;
    }

    .box-body {
        background-color: white;
        border: 2px solid lighten($color, 15%);
        border-top: 0;
    }
}

.box-orange {
    @include box-color($orangeColor);
}

.box-green {
    @include box-color($greenColor);
}

.box-blue {
    @include box-color($blueColor);
}

@media print {
    .star-ratings {
        h1 {
            color: black;
        }

        .stars {
            .title {
                color: black;
            }
        }
    }

    .box-orange,
    .box-green,
    .box-blue {
        .box-header {
            background-color: transparent;
            color: black;
        }

        .box-body {
            background-color: transparent;
            border: none;
        }
    }
}
