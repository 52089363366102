@import "variables";
@import "mixins";

.br-theme-bars-reversed {

  .br-widget {
    height: 25px;
    white-space: nowrap;

    a {
      display: block;
      width: 22px;
      height: 22px;
      float: left;     
      background-color: rgba($primary-color,0.2);
      margin: 1px;
      font-size: 15px;
      font-weight: 400;
      line-height: 1.4;
      color: $primary-color;
      text-align: center;

      &.br-active,
      &.br-selected {
        background-color: $primary-color;
        color: white;
      }
    }

    .br-current-rating {
      line-height: 1.3;
      float:left;
      padding:0 20px 0 20px;
      color:$primary-color;
      font-size:16px;
      font-weight:600;
    }
  }

  .br-readonly {
    a {
      cursor: default;

      &.br-active, &.br-selected {
        background-color: lighten($primary-color, 10%);
      }
    }

    .br-current-rating {
      color: lighten($primary-color, 10%);
    }
  }

}

@media print {
  .br-theme-bars-reversed {

    .br-widget {
      a {
        border: 1px solid lighten(black, 70%);
        background: white;
        @include box-sizing(border-box);

        &.br-active,
        &.br-selected {
          border: 1px solid black;
          background: white;
        }
      }

      .br-current-rating {
        color: black;
      }
    }

  }
}
